var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"download-box"},[_vm._m(2),_c('div',{staticClass:"download-box-right"},[_c('div',{staticClass:"download-box-right-box",staticStyle:{"margin-top":"0.3rem"},on:{"click":_vm.downloadClickAndroid}},[_vm._m(3),_c('div',[_vm._v(" Android下载 ")])]),_c('div',{staticClass:"download-box-right-box",on:{"click":_vm.downloadClickIos}},[_vm._m(4),_c('div',[_vm._v(" IOS下载 ")])])])]),_c('div',{staticClass:"swiper-box"},[_c('el-carousel',{attrs:{"height":"7rem","type":"card","autoplay":true,"loop":true,"interval":2000,"indicator-position":"none"}},_vm._l((_vm.swiperData),function(item){return _c('el-carousel-item',{key:item.key},[_c('div',{staticClass:"swiper-img-box"},[_c('img',{staticClass:"swiper-img-box-img",attrs:{"src":item.url}})])])}),1)],1),_vm._m(5),_c('div',{staticClass:"copyright-text"},[_vm._v("版权所有@广西柒季信息科技有限公司 2022")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-img"},[_c('img',{attrs:{"src":require("@/assets/img/topImg2.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-title"},[_c('div',{staticClass:"img-title"},[_c('img',{attrs:{"src":require("@/assets/img/title.webp"),"alt":""}})]),_c('div',{staticClass:"character-text"},[_c('div',[_vm._v("网红女神赛事直播 行业专家赛前预测")]),_c('div',[_vm._v("体育视听盛宴 万千佳人相伴")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-box-left"},[_c('img',{attrs:{"src":require("@/assets/img/erweima.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://sta-fe.zkreen.com/download/icon-android@2x.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://sta-fe.zkreen.com/download/icon-ios@3x.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"install-course"},[_c('div',{staticClass:"course-title"},[_c('h2',{staticClass:"course-title-left"},[_vm._v("安装教程")]),_c('div',{staticClass:"course-title-right"},[_vm._v(" 需在同一网络环境下下载安装和注册，勿切换网络若无法正常下载，请使用手机自带浏览器打开（Chrome谷歌浏览器、Safari浏览器） ")])]),_c('div',{staticClass:"course-box"},[_c('div',{staticClass:"course-box-title title-top"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/iosCourse.webp"),"alt":""}})]),_c('div',[_vm._v("IOS如何安装？")])]),_c('div',{staticClass:"title-top"},[_vm._v("1. APP 下载完成后，请打开「设置」>点选「通用」选项")]),_c('div',{staticClass:"title-top"},[_vm._v("2. 点选「设备管理」")]),_c('div',{staticClass:"title-top"},[_vm._v("3. 点击「信任」APP 即完成设置")])]),_c('div',{staticClass:"course-box"},[_c('div',{staticClass:"course-box-title title-top"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/andCourse.webp"),"alt":""}})]),_c('div',[_vm._v("Android如何安装？")])]),_c('div',{staticClass:"title-top"},[_vm._v("1. 点击本頁的「立即下载」选择「本地下载」")]),_c('div',{staticClass:"title-top"},[_vm._v("2. APP下载完成后，选择允许安装应用，即可完成安装")]),_c('div',{staticClass:"install-img-box"},[_c('div',{staticClass:"install-img"},[_c('img',{attrs:{"src":require("@/assets/img/install1.png"),"alt":""}})]),_c('div',{staticClass:"install-img install-img-margin"},[_c('img',{attrs:{"src":require("@/assets/img/install2.png"),"alt":""}})])])])])
}]

export { render, staticRenderFns }