<template>
  <div class="box-content">
    <!-- 图片 -->
    <div class="top-img">
      <img src="@/assets/img/topImg2.jpg"
           alt="">
    </div>

    <!-- 文字title -->
    <div class="text-title">
      <!-- 图片Title -->
      <div class="img-title">
        <img src="@/assets/img/title.webp"
             alt="">
      </div>
      <!-- 文字 Title -->
      <div class="character-text">
        <div>网红女神赛事直播 行业专家赛前预测</div>
        <div>体育视听盛宴 万千佳人相伴</div>
      </div>
    </div>

    <!-- download -->
    <div class="download-box">
      <!-- 左二维码 -->
      <div class="download-box-left">
        <img src="@/assets/img/erweima.png"
             alt="">
      </div>
      <!-- 右下载按钮 -->
      <div class="download-box-right">
        <div class="download-box-right-box"
             @click="downloadClickAndroid"
             style="margin-top:0.3rem">
          <div>
            <img src="https://sta-fe.zkreen.com/download/icon-android@2x.png"
                 alt="">
          </div>
          <div>
            Android下载
          </div>
        </div>
        <div class="download-box-right-box"
             @click="downloadClickIos">
          <div>
            <img src="https://sta-fe.zkreen.com/download/icon-ios@3x.png"
                 alt="">
          </div>
          <div>
            IOS下载
          </div>
        </div>
      </div>
    </div>

    <!-- swiper -->
    <div class="swiper-box">
      <el-carousel height="7rem"
                   type="card"
                   :autoplay="true"
                   :loop="true"
                   :interval="2000"
                   indicator-position="none">
        <el-carousel-item v-for="item in swiperData"
                          :key="item.key">
          <div class="swiper-img-box">
            <img class="swiper-img-box-img"
                 :src="item.url">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 安装教程 -->
    <div class="install-course">
      <!-- title -->
      <div class="course-title">
        <h2 class="course-title-left">安装教程</h2>
        <div class="course-title-right">
          需在同一网络环境下下载安装和注册，勿切换网络若无法正常下载，请使用手机自带浏览器打开（Chrome谷歌浏览器、Safari浏览器）
        </div>
      </div>

      <!-- IOS安装 -->
      <div class="course-box">
        <div class="course-box-title title-top">
          <div>
            <img src="@/assets/img/iosCourse.webp"
                 alt="">
          </div>
          <div>IOS如何安装？</div>
        </div>
        <div class="title-top">1. APP 下载完成后，请打开「设置」>点选「通用」选项</div>
        <div class="title-top">2. 点选「设备管理」</div>
        <div class="title-top">3. 点击「信任」APP 即完成设置</div>
      </div>

      <!-- and教程 -->
      <div class="course-box">
        <div class="course-box-title title-top">
          <div>
            <img src="@/assets/img/andCourse.webp"
                 alt="">
          </div>
          <div>Android如何安装？</div>
        </div>
        <div class="title-top">1. 点击本頁的「立即下载」选择「本地下载」</div>
        <div class="title-top">2. APP下载完成后，选择允许安装应用，即可完成安装</div>
        <div class="install-img-box">
          <div class="install-img">
            <img src="@/assets/img/install1.png"
                 alt="">
          </div>
          <div class="install-img install-img-margin">
            <img src="@/assets/img/install2.png"
                 alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- 版权所有 -->
    <div class="copyright-text">版权所有@广西柒季信息科技有限公司 2022</div>
  </div>
</template>

<script>
import swp1 from '@/assets/img/swp1.png'
import swp2 from '@/assets/img/swp2.png'
import swp3 from '@/assets/img/swp3.png'
export default {
  data () {
    return {
      swiperData: [
        { key: '1', url: swp1 },
        { key: '2', url: swp2 },
        { key: '3', url: swp3 },
      ]
    }
  },
  methods: {
    downloadClickAndroid () {
      window.open('https://obs.aeraneous.com/3c2e872f5e0641ad9faa20e7701e8dc9.apk')
    },
    downloadClickIos () {
      window.open('https://h5down.souqiu.tv/')
    }
  }

}
</script>

<style lang="less" scoped>
.box-content {
  width: 100%;
  height: 100vh;
  .top-img {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .text-title {
    .img-title {
      img {
        width: 100%;
      }
    }
    .character-text {
      font-size: 0.35rem;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #bc7f6d;
      font-weight: 700;
    }
  }

  .download-box {
    margin-top: 0.1rem;
    display: flex;
    justify-content: center;
    .download-box-left {
      width: 2rem;
      img {
        width: 100%;
      }
    }
    .download-box-right {
      margin-left: 0.35rem;
      line-height: 0.8rem;
      .download-box-right-box {
        cursor: pointer;
        width: 2rem;
        height: 0.5rem;
        color: #fff;
        border-radius: 4px;
        background-color: rgb(247, 106, 106);
        font-size: 0.16rem;
        margin-top: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 0.3rem;
          margin-right: 3px;
        }
      }
      .download-btn {
        width: 2rem;
        height: 0.5rem;
        background-color: rgb(247, 106, 106);
        border: none;
        .download-btn-box {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 0.18rem;
            margin-right: 0.01rem;
            margin-bottom: 0.01rem;
          }
        }
      }
    }
  }

  .swiper-box {
    width: 100%;
    margin: 0 auto;

    .swiper-img-box {
      .swiper-img-box-img {
        width: 3rem;
        height: 7rem;
        margin: 0 auto;
      }
    }
  }

  .install-course {
    .course-title {
      width: 90%;
      margin: 0 auto;
      border-bottom: 1px solid #c4d6ee;
      font-size: 0.18rem;
      padding-bottom: 0.2rem;
      display: flex;
      .course-title-left {
        color: #bc7f6d;
        font-size: 0.22rem;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        font-weight: 700;
        width: 20%;
        flex: 0 0 auto;
      }
      .course-title-right {
        text-align: left;
        color: #bc7f6d;
      }
    }
    .course-box {
      width: 90%;
      margin: 0 auto;
      font-size: 0.18rem;
      text-align: left;
      color: #d8ad66;
      border-bottom: 1px solid #c4d6ee;
      padding: 15px 0 50px 0;

      .course-box-title {
        color: #ea3434;
        font-size: 0.22rem;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        font-weight: 700;
        display: flex;
        align-items: center;
        img {
          width: 0.25rem;
          margin-right: 5px;
        }
      }
      .title-top {
        margin-top: 8px;
      }

      .install-img-box {
        margin-top: 25px;
        .install-img {
          width: 100%;
          height: 2.5rem;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .install-img-margin {
          margin: 0.45rem 0 0 0.12rem;
        }
      }
    }
  }

  .copyright-text {
    font-size: 0.18rem;
    padding: 2px 0 7px 0;
  }
}

/deep/.el-carousel__indicators--horizontal {
  position: absolute;
  text-align: right;

  .el-carousel__indicator--horizontal button {
    width: 0.08rem;
    height: 0.08rem;
    background: red;
    border-radius: 50%;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 0.24rem;
    height: 0.08rem;
    background: red;
    border-radius: 10px;
  }
}
</style>