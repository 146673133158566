import Vue from "vue";
import { Button, Carousel, CarouselItem } from "element-ui";
import App from "./App.vue";
import "./assets/style.css";

Vue.use(Button);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
