<template>
  <div id="app">
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  methods: {
    device () {
      let deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 750) deviceWidth = 750;
      document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
    },
  },
  mounted () {
    this.device();
    window.onresize = () => {
      return (() => {
        this.device();
      })();
    };
  },
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
